<template>
	<div class="p-fluid p-grid">
        <div class="p-grid p-col-12 p-md-12 p-lg-4">
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Name')}}:</strong> {{$store.state.person.name}}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>ID / {{$t('Passport')}}:</strong> {{$store.state.person.ident_passport}}
            </div>            
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Nationality')}}:</strong> {{$store.state.person.nationality[i18n.locale()]}}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Residence')}}:</strong> {{ $store.state.person.residence }}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Profession')}}:</strong> {{ $store.state.person.profession }}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Politically exposed person')}} (PEP):</strong> {{ $store.state.person.pep[i18n.locale()] }}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Email')}}:</strong> {{ $store.state.person.email }}
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <strong>{{$t('Telephone')}}:</strong> {{ $store.state.person.telephone }}
            </div>
        </div>    
		<div class="p-grid p-col-12 p-md-12 p-lg-4" style="padding: 1em 3em 1em 3em;">
			<div class="p-col-12 p-md-12 p-lg-12" v-if="$store.state.person.doc_passport">
				<img style="width: 100%;" :src="url+'upload/'+$store.state.person.doc_passport" @click="View($store.state.person.doc_passport)"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12" v-if="$store.state.person.form">
				<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.person.form)"/>
			</div>

		</div>
		<div class="p-col-12 p-md-12 p-lg-4" style="vertical-align: bottom; padding: 1em 5em 1em 5em;">
			<h5>{{$t('Checklist')}}</h5>
			<div v-for="(stats, index) in $store.state.person.check" :key="index" style="padding-top: 1em;">
				<Checkbox v-model="stats.activo" :binary="true"/>
				<label class="p-ml-2">{{ stats[i18n.locale()] }}</label>
			</div>
		</div>
	</div>
</template>

<script>
//import API from "../service/API";
//var Consult = new API('Registration','Uploader');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";

export default {
    props: {
		alls: {
			type: Boolean,
			default: true,
		},
		nations: {
			type: Array,
			default: null,
		},
		option: {
			type: Array,
			default: null,
		}
	},
    data() {
        return {
            size: '40vw',
            nationalitys: this.nations,
            options: this.option,
			token: null,
			url: process.env.VUE_APP_API,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		const tokgen = new Token(256);
        this.token = tokgen.generate();
    },
    watch: {
	},
    methods: {
		View(index){
			window.open(this.url+'upload/'+index, '_blank');
		},

    },


}
</script>